// Scripts only need on single pages
(function($) {

// Contextual header
$(document).ready(function(){

    var previousScroll = 0;
    navOffset = 200;

    var scroll_down = 1;
    var scroll_up = 1;


    function showContextualHeaderOnScroll(){
    
        var currentScroll = $(window).scrollTop();  

    //only on single pages
    if (document.body.classList.contains('single')) {
        //console.log(currentScroll);
        
        if (currentScroll > navOffset) {
            if (currentScroll > previousScroll) {
                scroll_down++;
                scroll_up = 1;
            } else {
                scroll_up++;
                scroll_down = 1;
            }
            //console.log(scroll_down);
            if (scroll_down%5 == 0){  //change 10 to another value for bigger treshold

                $('.sub-menu').addClass('hidden');
                $('#fixed-header').addClass("hide-overflow is-contextual");
            } 
            else if (scroll_up%5 == 0){
                $('#fixed-header').removeClass("is-contextual").stop().animate({opacity: 1}, 500, function() {
                  $(this).removeClass("hide-overflow");
                  $('.sub-menu').removeClass('hidden');
                });
            }
        } else {
            $('#fixed-header').removeClass("is-contextual");
            scroll_up = 1;
            scroll_down = 1;
        }
        previousScroll = currentScroll;
        }

    }  

    $(window).scroll(function () {
        showContextualHeaderOnScroll();
    });

    // For IOS < 8
    //$('body').on({'touchmove': function(e) { 
    $('body').bind('touchmove', function(e) {
        showContextualHeaderOnScroll();
    });


});



// Responsive Slides
/*! http://responsiveslides.com v1.55 by @viljamis */
// removed when minifying scripts
(function(c,K,C){c.fn.responsiveSlides=function(m){var a=c.extend({auto:!0,speed:500,timeout:4E3,pager:!1,nav:!1,random:!1,pause:!1,pauseControls:!0,prevText:"Previous",nextText:"Next",maxwidth:"",navContainer:"",manualControls:"",namespace:"rslides",before:c.noop,after:c.noop},m);return this.each(function(){C++;var f=c(this),u,t,v,n,q,r,p=0,e=f.children(),D=e.length,h=parseFloat(a.speed),E=parseFloat(a.timeout),w=parseFloat(a.maxwidth),g=a.namespace,d=g+C,F=g+"_nav "+d+"_nav",x=g+"_here",k=d+"_on",
y=d+"_s",l=c("<ul class='"+g+"_tabs "+d+"_tabs' />"),z={"float":"left",position:"relative",opacity:1,zIndex:2},A={"float":"none",position:"absolute",opacity:0,zIndex:1},G=function(){var b=(document.body||document.documentElement).style,a="transition";if("string"===typeof b[a])return!0;u=["Moz","Webkit","Khtml","O","ms"];var a=a.charAt(0).toUpperCase()+a.substr(1),c;for(c=0;c<u.length;c++)if("string"===typeof b[u[c]+a])return!0;return!1}(),B=function(b){a.before(b);G?(e.removeClass(k).css(A).eq(b).addClass(k).css(z),
p=b,setTimeout(function(){a.after(b)},h)):e.stop().fadeOut(h,function(){c(this).removeClass(k).css(A).css("opacity",1)}).eq(b).fadeIn(h,function(){c(this).addClass(k).css(z);a.after(b);p=b})};a.random&&(e.sort(function(){return Math.round(Math.random())-.5}),f.empty().append(e));e.each(function(a){this.id=y+a});f.addClass(g+" "+d);m&&m.maxwidth&&f.css("max-width",w);e.hide().css(A).eq(0).addClass(k).css(z).show();G&&e.show().css({"-webkit-transition":"opacity "+h+"ms ease-in-out","-moz-transition":"opacity "+
h+"ms ease-in-out","-o-transition":"opacity "+h+"ms ease-in-out",transition:"opacity "+h+"ms ease-in-out"});if(1<e.length){if(E<h+100)return;if(a.pager&&!a.manualControls){var H=[];e.each(function(a){a+=1;H+="<li><a href='#' class='"+y+a+"'>"+a+"</a></li>"});l.append(H);m.navContainer?c(a.navContainer).append(l):f.after(l)}a.manualControls&&(l=c(a.manualControls),l.addClass(g+"_tabs "+d+"_tabs"));(a.pager||a.manualControls)&&l.find("li").each(function(a){c(this).addClass(y+(a+1))});if(a.pager||a.manualControls)r=
l.find("a"),t=function(a){r.closest("li").removeClass(x).eq(a).addClass(x)};a.auto&&(v=function(){q=setInterval(function(){e.stop(!0,!0);var b=p+1<D?p+1:0;(a.pager||a.manualControls)&&t(b);B(b)},E)},v());n=function(){a.auto&&(clearInterval(q),v())};a.pause&&f.hover(function(){clearInterval(q)},function(){n()});if(a.pager||a.manualControls)r.bind("click",function(b){b.preventDefault();a.pauseControls||n();b=r.index(this);p===b||c("."+k).queue("fx").length||(t(b),B(b))}).eq(0).closest("li").addClass(x),
a.pauseControls&&r.hover(function(){clearInterval(q)},function(){n()});if(a.nav){g="<a href='#' class='"+F+" prev'>"+a.prevText+"</a><a href='#' class='"+F+" next'>"+a.nextText+"</a>";m.navContainer?c(a.navContainer).append(g):f.after(g);var d=c("."+d+"_nav"),I=d.filter(".prev");d.bind("click",function(b){b.preventDefault();b=c("."+k);if(!b.queue("fx").length){var d=e.index(b);b=d-1;d=d+1<D?p+1:0;B(c(this)[0]===I[0]?b:d);(a.pager||a.manualControls)&&t(c(this)[0]===I[0]?b:d);a.pauseControls||n()}});
a.pauseControls&&d.hover(function(){clearInterval(q)},function(){n()})}}if("undefined"===typeof document.body.style.maxWidth&&m.maxwidth){var J=function(){f.css("width","100%");f.width()>w&&f.css("width",w)};J();c(K).bind("resize",function(){J()})}})}})(jQuery,this,0);



// Responsive Slides init
$(document).ready(function(){
    $(".rslides").responsiveSlides({
        auto: true,             // Boolean: Animate automatically, true or false
        speed: 200,            // Integer: Speed of the transition, in milliseconds
        timeout: 3000,          // Integer: Time between slide transitions, in milliseconds
        nav: true,             // Boolean: Show navigation, true or false
        pauseControls: true,    // Boolean: Pause when hovering controls, true or false
    });
});




// Smooth scroll for on page links
$('a[href*=\\#]:not([href=\\#])').click(function() {

  if(location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {

    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

    if(target.length) {
      var buffer = 10;
      if ($(window).width() > 768) {
        buffer = 60;
      }
      $('html,body').animate({
        scrollTop: target.offset().top - buffer
      }, 1000);
      return false;
    }
  }

});







// Checklists
var items = document.querySelectorAll(".checklist .checkitem");

for (var i = 0; i < items.length; i++) {
    var item = items[i];
    if (item.hasAttribute("data-store")) {
        setupBox(item);
        setupStrikeout(item);
        console.log('item');
    }
}

function setupBox(item) {

    var box = item.querySelector("input[type='checkbox']");
    var storageId = item.getAttribute("data-store");
    var oldVal    = localStorage.getItem(storageId);
    box.checked = oldVal === "true" ? true : false;

    box.addEventListener("change", function() {
        localStorage.setItem(storageId, box.checked); 
        console.log('test');

    });
}

function setupStrikeout(item) {
    var toggle = item.querySelector(".need");
    var storageId = item.getAttribute("data-store") + "hide";
    var oldVal    = localStorage.getItem(storageId);
    item.className += oldVal === "striked" ? " strikeout" : "";

    toggle.addEventListener("click", function() {

        if(item.classList.contains('strikeout')) {
            localStorage.setItem(storageId, ''); 
            item.classList.remove('strikeout');
        } else {
            localStorage.setItem(storageId, 'striked');
            item.classList.add('strikeout');
        }
    });
}


$(document).ready(function(){
    $('[data-toggle="tablepopover"]').popover({
        container: 'body',
        trigger: 'hover',
        placement: 'top',
        html: 'true'
    });
});





})(jQuery);