(function($) {
//Quiz

var score = $('.quiz').attr('data-score');
var answered = $('.quiz').attr('data-answered');
var total = $('.quiz').children('li').length;


function markAnswer(q){

    q.find(".text-question input[type='text']").each(function(){  

        var answerString = $(this).attr('data-answer');
        var answerArray = answerString.split(', ');
        var guess = $(this).val().toLowerCase();

        if( $.inArray(guess, answerArray) !== -1 ){
        	q.find('.answer-reveal').addClass('answer-is-correct');
        	score++;
        } else {
        	q.find('.answer-reveal').addClass('answer-is-wrong');
        }

    })
}
    


function questionAnsered(q){
	//prevent reanwser
	q.removeClass('unanswered').addClass('answered');

	// Reveal answer text
	q.find('.answer-reveal').css('display','block');

	//are we done?
	answered++;
	if(answered === total){
		displayResults();
	}
}


function displayResults(){

	//show assessment
	var messages = $('.end-messages > *').length;
	var percent = score / total;
	var rating = Math.round(percent * messages);
	$('.assessment-' + rating).css('display','block');


	$('.quiz-result').css('display','block');
	$('.score').append('You got ' + score + ' out of ' + total + ' right!');

	var resultText = 'I scored ' + score + ' out of ' + total + '! How do you measure up?';
	var resultText = encodeURIComponent(resultText);

	var fbUrl = $('a.btn-facebook').attr('href');
	var twUrl = $('a.btn-twitter').attr('href');

	var newFbUrl = fbUrl.replace('ReplaceWithMyScore', resultText);
	var newTwUrl = twUrl.replace('ReplaceWithMyScore', resultText);

	$('a.btn-facebook').attr('href', newFbUrl);
	$('a.btn-twitter').attr('href', newTwUrl);
}




//Text input question
$(".unanswered form.text-question").on("submit", function(e){
    e.preventDefault();

    var q = $(this).parent();

    if( q.hasClass('unanswered')) {

		//Mark answer correct or wrong
	    markAnswer(q);

	    //Reveal answer box and prevent reanswer
		questionAnsered(q);
		
    }
    
    console.log('total questions: ' + total + '; score: ' + score + '; answered: ' + answered);

});





//Multiple choice question
$('.entry-content').on('click', '.unanswered .answer', function(){

	// How many answers are expected?
	var numAnswers = $(this).parent().parent().find('.answer[data-correct="1"]').length;

	// Select an answer
	$(this).addClass('chosen');

	// How many answers have been chosen?
	var numChosen = $(this).parent().parent().find('.chosen').length;

	// If number of chosen equals number of expected
	if(numAnswers == numChosen) {
		
		var q = $(this).parent().parent();

		// Highlight correct answer(s)
		q.find('.answer[data-correct="1"]').addClass('correct-answer');
		$(this).addClass('selected-answer');


		if($(this).parent().parent().find('.chosen[data-correct="0"]').length){
			q.find('.answer-reveal').addClass('answer-is-wrong');
		} else {
			q.find('.answer-reveal').addClass('answer-is-correct');
			score++;
		}

		// Reveal answer text
		questionAnsered(q);


	}

	console.log('total questions: ' + total + '; score: ' + score + '; answered: ' + answered);

});


})(jQuery);