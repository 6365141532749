/************************************************************************
// LAZY BACKGROUND
************************************************************************/

(function($) {

    let lazyObjectObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                let lazyObject = entry.target;
                if(!(lazyObject.dataset.lazybg == '')){
                    bgsrc = lazyObject.dataset.lazybg;
                    lazyObject.style.backgroundImage = 'url('+bgsrc+')';
                    lazyObject.classList.remove("lazybg");
                    lazyObject.dataset.lazybg = '';
                    lazyObjectObserver.unobserve(lazyObject);
                }
            }
        });
    },{ rootMargin: "0px 0px 0px 0px" });

    document.addEventListener("DOMContentLoaded", function() {
      var lazyObjects = [].slice.call(document.querySelectorAll(".lazybg"));
      lazyObjects.forEach(function(lazyObject) {
        lazyObjectObserver.observe(lazyObject);
    });
});



/************************************************************************
// ARTICLE PAGES
************************************************************************/

// FAQs
$(document).ready(function() {
  //Hide (Collapse) the toggle containers on load
  $(".faq-a").hide(); 
  //Switch the "Open" and "Close" state per click
  $(".faq-q").on('click', function(){
    $(this).parent().toggleClass("active");
  });
  //Slide up and down on click
  $(".faq-q").click(function(){
    $(this).next(".faq-a").slideToggle("fast");
  });
});


// SETUP TRACKING FOR AFFILIATE LINKS
//<a href="http://amzn.to/2a9xmG1" class="track" data-aff="Amazon" data-cat="Family Tents" data-item="Test Tent">test</a>
$(document).ready(function() {

  $("a.track").each(function() {
    var href = $(this).attr("href");
    var target = $(this).attr("target");
    var text = $(this).text();
    var aff = $(this).attr("data-aff");
    var cat = $(this).attr("data-cat");
    var category = aff + ' - ' + cat;
    var item = $(this).attr("data-item");

    $(this).click(function(event) { // when someone clicks these links
      event.preventDefault(); // don't open the link yet
      //_gaq.push(["_trackEvent", "Links", "Click", text, , true]); // create a custom event
      ga('send', 'event', category, 'Click', item, {nonInteraction: true});
      setTimeout(function() { // now wait 300 milliseconds...
        window.open(href,(!target?"_self":target)); // ...and open the link as usual
      },300);
    });
  });

});



// OPEN EXTERNAL LINKS IN NEW TABS – still needed?
$(".entry-content a[href^='http://amzn']").attr("target","_blank");
$(".entry-content a[href^='http://bit']").attr("target","_blank");
//$(".entry-content a[href^='https://']").attr("target","_blank");




// SCROLL TO TOP
$(document).ready(function(){
  // browser window scroll (in pixels) after which the "back to top" link is shown
  var offset = 300,
    //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
    offset_opacity = 1200,
    //duration of the top scrolling animation (in ms)
    scroll_top_duration = 700,
    //grab the "back to top" link
    $back_to_top = $('.nav-top');

  //hide or show the "back to top" link
  $(window).scroll(function(){
    ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('is-visible') : $back_to_top.removeClass('is-visible fade-out');
    if( $(this).scrollTop() > offset_opacity ) { 
      $back_to_top.addClass('fade-out');
    }
  });

  //smooth scroll to top
  $back_to_top.on('click', function(event){
    event.preventDefault();
    $('body,html').animate({
      scrollTop: 0 ,
      }, scroll_top_duration
    );
  });

});




// FACEBOOK SHARE
function fbShare(url, title, winWidth, winHeight) {
  var winTop = (screen.height / 2) - (winHeight / 2);
  var winLeft = (screen.width / 2) - (winWidth / 2);
  window.open('http://www.facebook.com/sharer.php?u=' + url + '&title=' + title, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight);
}



// PINTEREST SHARE
if (screen.width > 992) {
   (function(d){
        var f = d.getElementsByTagName('SCRIPT')[0], p = d.createElement('SCRIPT');
        p.type = 'text/javascript';
        p.setAttribute('data-pin-hover', true);
        p.setAttribute('data-pin-color', 'red');
        p.async = true;
        p.src = '//assets.pinterest.com/js/pinit.js';
        f.parentNode.insertBefore(p, f);
    }(document));
}

if (screen.width < 992) {
    function pinIt()
    {
        var e = document.createElement('script');
        e.setAttribute('type','text/javascript');
        e.setAttribute('charset','UTF-8');
        e.setAttribute('src','https://assets.pinterest.com/js/pinmarklet.js?r='+Math.random()*99999999);
        document.body.appendChild(e);
    }
}



/************************************************************************
// MAILCHIMP AJAX FORM
* @required parsley.min.js
************************************************************************/

(function(name, definition) {
    var theModule  = definition(),
        // this is considered "safe":
        hasDefine  = typeof define === "function" && define.amd,
        // hasDefine = typeof define === "function",
        hasExports = typeof module !== "undefined" && module.exports;

    if (hasDefine) { // AMD Module
        define(theModule);
    } else if (hasExports) { // Node.js Module
        module.exports = theModule;
    } else { // Assign to common namespaces or simply the global object (window)
        ( this.jQuery || this.ender || this.$ || this)[name] = theModule;
    }

    // Start
    theModule.init();

})("MailchimpAJAX", function() {
    var module = this;

    // Private variables
    module.$mailchimpForms = $("[data-mailchimp-ajax-form]"); // Elements to be toggled

    // Private methods
    return {

        // Public methods

        // Public method to init all fields
        init: function() {

            // Hook submit on each of the forms
            module.$mailchimpForms.submit(function(e) {
                e.preventDefault();

                var $form = $(this);

                // if is parsley validated or parsley is not available
                if (typeof $form.parsley() === 'undefined' || $form.parsley().isValid()) {

                    var messageTimeout      = $form.data('mailchimp-ajax-message-timeout') ? $form.data('mailchimp-ajax-message-timeout') : 50000;
                    var messageClassError   = $form.data('mailchimp-ajax-message-class-error') ? $form.data('mailchimp-ajax-message-class-error') : 'alert alert-danger';
                    var messageClassSuccess = $form.data('mailchimp-ajax-message-class-success') ? $form.data('mailchimp-ajax-message-class-success') : 'alert alert-success';

                    $.ajax({
                        type       : 'get',
                        url        : $form.attr('action').replace('/post', '/post-json').concat('&c=?').replace('-json&', '-json?'),
                        data       : $form.serialize(),
                        cache      : false,
                        dataType   : 'jsonp',
                        contentType: "application/json; charset=utf-8",
                        error      : function(err) {

                            $form.append('<div class="' + messageClassError + ' js-form-validation-' + $(".form-validation").length + '">' + err.msg + '</div>');

                            window.setTimeout(function() {
                                $(".js-form-validation-" + ($(".form-validation").length)).fadeOut(300);
                            }, messageTimeout);
                        },
                        success    : function(data) {

                            if (data.result !== "success") {
                                $form.append('<div class="' + messageClassError + ' js-form-validation-' + $(".js-form-validation").length + '">' + data.msg + '</div>');
                            } else {
                                $form.append('<div class="' + messageClassSuccess + ' js-form-validation-' + $(".js-form-validation").length + '">' + data.msg + '</div>');
                            }

                            window.setTimeout(function() {
                                $(".js-form-validation-" + ($(".js-form-validation").length)).fadeOut(300);
                            }, messageTimeout);
                        }
                    });

                }
            });
        }
    };
});




}(jQuery));