(function($) {



// get all resources
window.performance.getEntriesByType('resource')
  // only consider the blocking ones
  .filter(({renderBlockingStatus}) => 
      renderBlockingStatus === 'blocking')
  // log their names
  .forEach(({name}) => console.log(name))

// Logs: https://your-site.com/styles.css













/************************************************************************
// LAZY LOAD 
************************************************************************/


$(function() {
  var templateUrl = siteParameters.templateUrl;
  $('.lazy').Lazy({
    effect : "fadeIn",
    threshold : 300,
    placeholder: templateUrl + '/img/blank.png',
  });
});
     



/************************************************************************
// HEADER
************************************************************************/

// Sticky header
$(document).ready(function(){
    $("#fixed-header").sticky({topSpacing:0});
});


// Big search
$(document).ready(function(){
  $('.search-toggle').click(function(){
    $('body').toggleClass('search-active');
    $('.bigsearch-input').focus();
  })

  $('.bigsearch-close').click(function(){
    $('body').toggleClass('search-active');
  })
});





/************************************************************************
// ARTICLE PAGES
************************************************************************/


/* make sure tables are responsive */
$('table:not(.unresponsive)').addClass('table').wrap('<div class="table-responsive"></div>');








/************************************************************************
// MISCELLANEOUS
************************************************************************/


// Check hover is available
$(document).ready(function() {
  var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
  if (isTouch) {
    $('html').removeClass('hoverable');
  }
})


//Cookie Consent – https://cookieconsent.insites.com/
window.addEventListener("load", function(){
    setTimeout(function() {
      window.cookieconsent.initialise({
        palette: {
          popup: {background: '#000000'},
          button: {background: '#eba42e', text: '#ffffff'}
        },
        content: {
          href: 'privacy'
        },
        position:'top',
        //static: true, 
      })
    }, 5000);
});







/************************************************************************
// MOBILE MENU
************************************************************************/


$('#mob-nav').click(function(){
    $(this).toggleClass('closemob');
    $('body').toggleClass('mobmenu-active');
});


$(document).ready(function() {
    // Store the window width
    var windowWidth = $(window).width();
    // Resize Event
    $(window).resize(function(){
        // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
        if ($(window).width() != windowWidth) {
            // Update the window width for next time
            windowWidth = $(window).width();
            $('body').removeClass('mobmenu-active');
        }
        // Otherwise do nothing
    });
});


$('.mm-nav li.parent').find('a:first').click(function(e){
  e.preventDefault();
  $(this).next('.sub-menu').slideToggle();
});

$('.sub-menu').click(function(e){
  e.stopPropagation();
});










})(jQuery);
